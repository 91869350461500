import {CBadge, CButton, CButtonGroup, CCol, CLink, CPlaceholder, CRow, CSpinner} from '@coreui/react-pro'
import { createColumnHelper } from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'

import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { IPage } from '../../types/response/pagination'
import InfiniteScroll from 'react-infinite-scroll-component'
import CIcon from '@coreui/icons-react'
import {cidLockLocked, cisFile, cisFolder, cisPencil, cisUserFemaleX} from '@coreui/icons-pro'

import useToast from '../../hooks/toast'
import {ISection} from "../../types/entities/section";
import {GenericErrorHandler, GetGroup} from "../../helpers";
import api from "../../api";
import ComponenetTable from "../../componenets/Table";
import {IUser} from "../../types/entities/user";

export default function Table() {
    const [searchParams] = useSearchParams();
    const [data, setData] = useState<IPage<IUser>>()
    const [loading, setLoading] = useState(true)
    const columnHelper = createColumnHelper<IUser>()
    const navigate = useNavigate();
    const [addToast] = useToast()
    
    const columns = useMemo(() => [
        columnHelper.accessor(row => row.id, {
            id: 'id',
            cell: info => info.getValue(),
            header: () => '#',
        }),
        columnHelper.accessor(row => row.name, {
            id: 'name',
            // cell: info => info.getValue(),
            cell: info => info.getValue(),
            header: () => 'Name',
        }),
        columnHelper.accessor(row => row.email, {
            id: 'email',
            cell: info => info.getValue(),
            header: () => 'Email',
        }),
        columnHelper.accessor(row => row.is_admin, {
            id: 'admin',
            cell: info => {
                return info.getValue() ? 'Yes' : 'No';
            },
            header: () => 'Admin',
        }),
        columnHelper.accessor(row => row.created_at, {
            id: 'created_at',
            cell: info => info.getValue(),
            header: () => 'Created At',
        }),
        columnHelper.accessor(row => row, {
            id: 'mobile',
            cell: info => {
                const row = info.getValue()

                return (<CRow>
                    <CCol xs={12}>
                        <strong>{`#${row.id}`} {row.name}</strong> {row.is_admin && <CBadge color={'dark'}>Admin</CBadge>}
                    </CCol>
                    <CCol xs={12}>
                        {row.email}
                    </CCol>
                </CRow>)
            },
            header: () => 'Mobile',
        })
    ], [columnHelper, navigate, searchParams])

    const fetchMoreData = () => {
        if (data?.next_page_url) api.get(data.next_page_url).then(onfulfilled => {
            setData({ ...onfulfilled.data, data: [...data.data, ...onfulfilled.data.data] })
        }).catch(reason => {
            GenericErrorHandler({reason,addToast})
        });
    };


    useEffect(() => {
        setLoading(true)
        let sp = createSearchParams(searchParams)
        if (!searchParams.get('parent')) sp.set('is_root', 'true')

        api.get(`user?${sp.toString()}`).then(onfulfilled => {
            setData(onfulfilled.data)
            setLoading(false)
        }).catch(reason => {
            GenericErrorHandler({reason,addToast})
        });
    }, [addToast, searchParams])


    if (loading) return (
        <CPlaceholder animation="glow">
            <CPlaceholder xs={12} size="lg" />
        </CPlaceholder>
    )
    else return (<>
        <InfiniteScroll
            dataLength={data?.data.length || 0}
            next={fetchMoreData}
            hasMore={data?.next_page_url != null}
            loader={<div className='text-center m-2'>
                <CSpinner variant="grow" size='sm' /> <b>Memuat data</b>
            </div>}
        >
            <ComponenetTable
                data={data?.data || []}
                columns={columns}
                responsive
                onclickRow={(original: ISection) => {
                    navigate({ pathname: `/user/edit/${original.id}` })
                }}
            />

        </InfiniteScroll>

    </>)

}
