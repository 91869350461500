import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CForm, CFormInput, CLoadingButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle,
    CPlaceholder,
    CRow,
    CTable, CTableBody, CTableDataCell, CTableFoot,
    CTableHead, CTableHeaderCell,
    CTableRow
} from '@coreui/react-pro'
import React, {useContext, useEffect, useState} from 'react'
import {Context} from "../../../context";
import CIcon from "@coreui/icons-react";
import {cisTrash} from "@coreui/icons-pro";
import {IAspek} from "../../../types/entities/aspek";
import useToast from "../../../hooks/toast";
import {z} from "zod";
import {SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import api from "../../../api";
import {GenericErrorHandler} from "../../../helpers";
import useReloader from "../../../hooks/reloader";

const ModalDelete = ({id, setID, reload}: {id: number|undefined, setID: React.Dispatch<React.SetStateAction<number | undefined>>, reload:  () => void}) => {
    const FormSchema = z.object({
    });
    const [addToast] = useToast()
    type FormSchemaType = z.infer<typeof FormSchema>;

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
        getValues,
        reset
    } = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        defaultValues: ({ is_confirmed: false })
    })

    const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {

        await api.delete(`/aspek/${id}`).then(() => {
            addToast({ color: "success", message: `Delete Aspek #${id} successed` })
            reload()
            setID(undefined)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        })
    }

    return (<CModal
        visible={id !== undefined}
        onClose={() => setID(undefined)}
    >
        <CModalBody>
            <CForm
                id='formDelete'
                className="row"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete='false'
            >

            </CForm>
            Apakah anda yakin ingin menghapus <strong>Aspek #{id}</strong> ?
        </CModalBody>
        <CModalFooter>
            <CButton color="secondary" onClick={() => setID(undefined)}>
                Tidak
            </CButton>
            <CLoadingButton color='danger' form='formDelete' loading={isSubmitting} type="submit" className='me-2'>Hapus</CLoadingButton>
        </CModalFooter>
    </CModal>)
}

const ComponenetAspek = ({section_id}:{section_id:string}) => {
    const [isLoadingAspeks, setIsLoadingAspeks] = useState(true)
    const [aspeks, setAspeks] = useState<IAspek[]>([])
    const [addToast] = useToast()
    const {notification, reload} = useReloader()
    const [idDeleteAspek, setIdDeleteAspek] = useState<number|undefined>(undefined)


    const FormSchema = z.object({
        aspek: z.string().nullable(),
        score: z.string().nullable(),
    });
    type FormSchemaType = z.infer<typeof FormSchema>;
    const initial = {
        aspek: null,
        score: null,
    };
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
        control,
        reset,
        watch,
        getValues,
        setValue
    } = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        defaultValues: initial
    })

    const instance = watch()

    const additionalErrorHandler = (reason : any) => {
        for (let item in reason.response.data.errors) {
            setError(item as ("aspek" | "score"), { type: 'custom', message: reason.response.data.errors[item][0] })
        }
    }

    useEffect(() => {
        setIsLoadingAspeks(true)
        api.get(`/aspek?section_id=${section_id}`).then(onfulfilled => {
            setAspeks(onfulfilled.data)
            setIsLoadingAspeks(false)
        }).catch(reason => {
            if (reason.code === "ERR_NETWORK") {
                addToast({ message: reason.message, color: "danger" })
            } else {
                try {
                    const dataError = reason.response.data;
                    addToast({ message: dataError["message"], color: "danger" })
                } catch {
                    addToast({ message: "Undefined Error", color: "danger" })
                }
            }
        })
    }, [addToast, section_id, notification]);

    const onCreate: SubmitHandler<FormSchemaType> = async (data) => {
        await api.post('/aspek', {...data, section_id : section_id}).then(() => {
            addToast({ message: `Add Aspek for Section #${section_id} Successed`, color: "success" })
            reset(initial)
            reload();
        }).catch(reason => {
            GenericErrorHandler({reason, addToast, additionalErrorHandler})
        });
    }

    return (<>
        {isLoadingAspeks && <CPlaceholder xs={12} size="lg" />}
        {!isLoadingAspeks && <CForm
            id='formSubmit'
            className="row"
            onSubmit={handleSubmit(onCreate)}
            autoComplete='false'
        >
            <CTable className={'mt-5'} responsive striped hover>
                <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell>#</CTableHeaderCell>
                        <CTableHeaderCell>Aspek</CTableHeaderCell>
                        <CTableHeaderCell>Score</CTableHeaderCell>
                        <CTableHeaderCell></CTableHeaderCell>
                    </CTableRow>
                </CTableHead>
                <CTableBody>
                    { aspeks.map((aspek, key) => {
                        return (<CTableRow key={key}>
                            <CTableDataCell>{aspek.id}</CTableDataCell>
                            <CTableDataCell>{aspek.aspek}</CTableDataCell>
                            <CTableDataCell>{aspek.score}</CTableDataCell>
                            <CTableDataCell>
                                <CButton variant={"outline"} size={"sm"} onClick={() => {
                                    setIdDeleteAspek(aspek.id)
                                }}><CIcon icon={cisTrash}/></CButton>
                            </CTableDataCell>
                        </CTableRow>)
                    }) }

                </CTableBody>

                <CTableFoot>
                    <CTableRow>
                        <CTableDataCell></CTableDataCell>
                        <CTableDataCell>
                            <CFormInput
                                placeholder='Aspek'
                                size={"sm"}
                                invalid={errors.aspek != null}
                                feedbackInvalid={errors.aspek?.message as string}
                                {...register('aspek')}
                            />
                        </CTableDataCell>
                        <CTableDataCell>
                            <CFormInput
                                placeholder='Score'
                                size={"sm"}
                                type={'number'}
                                invalid={errors.score != null}
                                feedbackInvalid={errors.score?.message as string}
                                {...register('score')}
                            />
                        </CTableDataCell>
                        <CTableDataCell>
                            <CLoadingButton size={"sm"} form='formSubmit' loading={isSubmitting} type="submit" className='w-100'>Tambah</CLoadingButton>
                        </CTableDataCell>
                    </CTableRow>
                </CTableFoot>
            </CTable>
        </CForm>}
        <ModalDelete id={idDeleteAspek} setID={setIdDeleteAspek} reload={reload}/>
    </>)
}

export default ComponenetAspek


