import {
    CButton,
    CCol,
    CCollapse,
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CFormSwitch,
    CFormTextarea,
    CInputGroup,
    CLoadingButton,
    CPlaceholder
} from '@coreui/react-pro'
import { z } from 'zod';
import { useForm, SubmitHandler } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod';

import { useNavigate } from 'react-router-dom'
import useToast from '../../hooks/toast'
import { useEffect, useState } from 'react';
import useModalDelete from '../../hooks/modalDelete';
import { cisChevronBottom, cisChevronTop } from '@coreui/icons-pro';
import CIcon from '@coreui/icons-react';

import api from "../../api";
import {GenericErrorHandler} from "../../helpers";

export function FormAddEdit({ edit, title }: { edit?: string, title: string }) {
    const [collapseVisible, setCollapseVisible] = useState(false)
    const [addToast] = useToast()
    const navigate = useNavigate();
    const [isRetrievingData, setIsRetrievingData] = useState(false)
    const FormSchema = z.object({
        name: z.string().nullable(),
        email: z.string().nullable(),
        is_admin: z.boolean(),
        password: z.string().optional(),
        password_confirmation: z.string().optional(),
    });

    type FormSchemaType = z.infer<typeof FormSchema>;
    const initial = {
        name: null,
        email: null,
        is_admin: false,
        password: undefined,
        password_confirmation: undefined,
    };
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
        control,
        reset,
        watch,
        getValues,
        setValue
    } = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        defaultValues: (edit !== undefined
            ? initial
            : { ...JSON.parse(localStorage.getItem(title) || JSON.stringify(initial)) }
        )
    })

    const instance = watch()

    useEffect(() => {
        if (edit === undefined) {
            localStorage.setItem(title, JSON.stringify(instance))
        }
    }, [edit, instance, title])

    useEffect(() => {
        if (edit !== undefined) {
            setIsRetrievingData(true)
            api.get(`/user/${edit}`).then(onfulfilled => {
                if (onfulfilled.data) {
                    reset(onfulfilled.data)
                }
                setIsRetrievingData(false)
            }).catch(reason => {
                GenericErrorHandler({reason, addToast})
            })
        } else {
            setIsRetrievingData(false)
        }
    }, [addToast, edit, reset])

    const additionalErrorHandler = (reason : any) => {
        for (let item in reason.response.data.errors) {
            setError(item as ("name" | "email" | "is_admin" | "password" | "password_confirmation"), { type: 'custom', message: reason.response.data.errors[item][0] })
        }
    }

    const onCreate: SubmitHandler<FormSchemaType> = async (data) => {
        await api.post('/user', data).then(() => {
            addToast({ message: `${title} Successed`, color: "success" })
            navigate(-1)
            reset(initial)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast, additionalErrorHandler})
        });
    }

    const onUpdate: SubmitHandler<FormSchemaType> = async (data) => {
        if(data.password === '')
            data = {...data, password: undefined, password_confirmation: undefined}

        await api.put(`/user/${edit}`, data).then(() => {
            addToast({ message: `${title} Successed`, color: "success" })
            navigate(-1)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast, additionalErrorHandler})
        });
    }

    const [UiModalDelete, UiButtonDelete] = useModalDelete({
        title: `Delete User #${edit}`,
        path: `/user/${edit}`,
        confirmation: <>Saya yakin ingin menghapus <strong>User #{edit}</strong></>
    })

    if (isRetrievingData) return (<CPlaceholder xs={12} size="lg" />)

    return (<>
        <CForm
            id='formSubmit'
            className="row"
            onSubmit={handleSubmit(edit !== undefined ? onUpdate : onCreate)}
            autoComplete='false'
        >
            <CFormLabel htmlFor="name" className="col-md-2 col-form-label">Name</CFormLabel>
            <CCol md={10} className='mb-3'>
                <CFormInput
                    placeholder='Name'
                    invalid={errors.name != null}
                    feedbackInvalid={errors.name?.message as string}
                    {...register('name')}
                />
            </CCol>
            <CFormLabel htmlFor="name" className="col-md-2 col-form-label">Email</CFormLabel>
            <CCol md={10} className='mb-3'>
                <CFormInput
                    placeholder='Email'
                    invalid={errors.email != null}
                    feedbackInvalid={errors.email?.message as string}
                    {...register('email')}
                />
            </CCol>
            <CCol md={10} className='offset-md-2 mb-3' >
                <CFormSwitch
                    label="Sebagai Admin"
                    invalid={errors.is_admin != null}
                    {...register('is_admin')}
                />
            </CCol>
            <CFormLabel htmlFor="name" className="col-md-2 col-form-label">Password</CFormLabel>
            <CCol md={10} className='mb-3'>
                <CFormInput
                    placeholder='Password'
                    text={edit && 'kosongkan jika tidak merubah password'}
                    type={'password'}
                    invalid={errors.password != null}
                    feedbackInvalid={errors.password?.message as string}
                    {...register('password')}
                />
            </CCol>
            <CFormLabel htmlFor="name" className="col-md-2 col-form-label">Password Comfirmation</CFormLabel>
            <CCol md={10} className='mb-3'>
                <CFormInput
                    placeholder='Password Comfirmation'
                    type={'password'}
                    invalid={errors.password_confirmation != null}
                    feedbackInvalid={errors.password_confirmation?.message as string}
                    {...register('password_confirmation')}
                />
            </CCol>

            <CCol md={10} className='mb-3 offset-md-2'>
                <CLoadingButton form='formSubmit' loading={isSubmitting} type="submit" className='me-2'>{edit ? 'Update' : 'Create'}</CLoadingButton>
                <CLoadingButton color='secondary' className='me-2' onClick={() => {
                    reset(initial)
                }}>Clear</CLoadingButton>
                {edit && <UiButtonDelete />}
            </CCol>
        </CForm>
        {edit && <UiModalDelete />}
    </>)
}
