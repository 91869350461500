import {useState} from "react";

export default function useReloader(){
    const [notification, setNotification] = useState(0)

    const reload = () => {
        setNotification(prevCount => prevCount + 1);
    };

    return {notification, reload}
}