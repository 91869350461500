import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol, CForm, CFormInput, CLoadingButton,
    CPlaceholder,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell, CTableFoot, CTableHead, CTableHeaderCell,
    CTableRow
} from '@coreui/react-pro'

import {useNavigate, useParams} from 'react-router-dom'
import {useContext, useEffect, useState} from 'react'
import {Context} from "../../../context";
import {ISection} from "../../../types/entities/section";
import {IAspek} from "../../../types/entities/aspek";
import api from "../../../api";
import useToast from "../../../hooks/toast";
import {GenericErrorHandler, GetGroup} from "../../../helpers";
import CIcon from "@coreui/icons-react";
import {cilPlus, cisChevronBottom, cisChevronTop, cisTrash} from "@coreui/icons-pro";
import {z} from "zod";
import {SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import useReloader from "../../../hooks/reloader";
import useModalDelete from "../../../hooks/modalDelete";
import ComponenetAspek from "./componenet.aspek";

const Index = () => {

    const { setMetaData } = useContext(Context)
    const params = useParams();
    const title = `Detail Section #${params.id}`

    const [isLoadingSection, setIsLoadingSection] = useState(true)

    const [section, setSection] = useState<ISection>({})

    const [addToast] = useToast()

    useEffect(() => {
        setMetaData({ title })

        api.get(`/section/${params.id}`).then(onfulfilled => {
            setSection(onfulfilled.data)
            setIsLoadingSection(false)
        }).catch(reason => {
            if (reason.code === "ERR_NETWORK") {
                addToast({ message: reason.message, color: "danger" })
            } else {
                try {
                    const dataError = reason.response.data;
                    addToast({ message: dataError["message"], color: "danger" })
                } catch {
                    addToast({ message: "Undefined Error", color: "danger" })
                }
            }
        })
    }, [setMetaData, title])

    return (<>
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader >
                        <strong>{title}</strong>
                    </CCardHeader>
                    <CCardBody>
                        {isLoadingSection && <CPlaceholder xs={12} size="lg" />}
                        {!isLoadingSection && <CTable responsive hover striped>
                            <CTableBody>
                                <CTableRow>
                                    <CTableDataCell>Name</CTableDataCell>
                                    <CTableDataCell><strong>{section.name}</strong></CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableDataCell>Group</CTableDataCell>
                                    <CTableDataCell><strong>{GetGroup(section.group)}</strong></CTableDataCell>
                                </CTableRow>
                                <CTableRow>
                                    <CTableDataCell>Status</CTableDataCell>
                                    <CTableDataCell><strong>{section.is_active ? 'Active' : 'Inactive'}</strong></CTableDataCell>
                                </CTableRow>
                            </CTableBody>
                        </CTable>}

                        <ComponenetAspek section_id={params.id as string} />
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>

    </>)
}

export default Index