import { CButton, CButtonGroup, CCol, CLink, CPlaceholder, CRow, CSpinner } from '@coreui/react-pro'
import { createColumnHelper } from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'

import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { IPage } from '../../types/response/pagination'
import InfiniteScroll from 'react-infinite-scroll-component'
import CIcon from '@coreui/icons-react'
import { cidLockLocked, cisFile, cisFolder, cisPencil } from '@coreui/icons-pro'

import useToast from '../../hooks/toast'
import {ISection} from "../../types/entities/section";
import {GenericErrorHandler, GetGroup} from "../../helpers";
import api from "../../api";
import ComponenetTable from "../../componenets/Table";

export default function Table() {
    const [searchParams] = useSearchParams();
    const [data, setData] = useState<IPage<ISection>>()
    const [loading, setLoading] = useState(true)
    const columnHelper = createColumnHelper<ISection>()
    const navigate = useNavigate();
    const [addToast] = useToast()
    
    const columns = useMemo(() => [
        columnHelper.accessor(row => row.id, {
            id: 'id',
            cell: info => info.getValue(),
            header: () => '#',
        }),
        columnHelper.accessor(row => row, {
            id: 'name',
            // cell: info => info.getValue(),
            cell: info => {
                const row = info.getValue()
                return (<CLink style={{ cursor: 'pointer' }} onClick={() => {
                    navigate({ pathname: `/section/detail/${row.id}` })
                }}>{`${row.name}`}</CLink>)
            },
            header: () => 'Section',
        }),
        columnHelper.accessor(row => row.group, {
            id: 'group',
            cell: info => GetGroup(info.getValue()),
            header: () => 'Group',
        }),
        columnHelper.accessor(row => row, {
            id: 'actions',
            cell: info => {
                const row = info.getValue()
                return (<CButtonGroup className='float-end' size='sm' role="group">
                    {[
                        { to: `edit/${row.id}`, icon: cisPencil },
                    ].map((x, key) => <CButton key={key} style={{ textDecoration: 'none' }} variant='outline' onClick={() => {
                        navigate(x.to)
                    }}>
                        <CIcon icon={x.icon} />
                    </CButton>)}
                </CButtonGroup>)
            },
            header: () => <></>,
        }),
        columnHelper.accessor(row => row, {
            id: 'mobile',
            cell: info => {
                const row = info.getValue()

                return (<CRow>
                    <CCol xs={12}>
                        <span style={{ textDecoration: !row.is_active ? 'line-through' : '' }}>
                            {row.name}
                        </span>
                    </CCol>
                    <CCol xs={12}>
                        {GetGroup(row.group)}
                    </CCol>
                    <CCol xs={12}>
                        <CButtonGroup className='w-100 my-3' size='sm' role="group">
                            {[
                                { to: `edit/${row.id}`, icon: cisPencil, text: 'Edit' },
                            ].map((x, key) => <CButton key={key} style={{ textDecoration: 'none' }} variant='outline' onClick={() => {
                                navigate(x.to)
                            }}>
                                <CIcon icon={x.icon} className='me-2' />{x.text}
                            </CButton>)}
                        </CButtonGroup>
                    </CCol>
                </CRow>)
            },
            header: () => 'Mobile',
        })
    ], [columnHelper, navigate, searchParams])

    const fetchMoreData = () => {
        if (data?.next_page_url) api.get(data.next_page_url).then(onfulfilled => {
            setData({ ...onfulfilled.data, data: [...data.data, ...onfulfilled.data.data] })
        }).catch(reason => {
            GenericErrorHandler({reason,addToast})
        });
    };


    useEffect(() => {
        setLoading(true)
        let sp = createSearchParams(searchParams)
        if (!searchParams.get('parent')) sp.set('is_root', 'true')

        api.get(`section?${sp.toString()}`).then(onfulfilled => {
            setData(onfulfilled.data)
            setLoading(false)
        }).catch(reason => {
            GenericErrorHandler({reason,addToast})
        });
    }, [addToast, searchParams])


    if (loading) return (
        <CPlaceholder animation="glow">
            <CPlaceholder xs={12} size="lg" />
        </CPlaceholder>
    )
    else return (<>
        <InfiniteScroll
            dataLength={data?.data.length || 0}
            next={fetchMoreData}
            hasMore={data?.next_page_url != null}
            loader={<div className='text-center m-2'>
                <CSpinner variant="grow" size='sm' /> <b>Memuat data</b>
            </div>}
        >
            <ComponenetTable
                data={data?.data || []}
                columns={columns}
                responsive
                // onclickRow={(original: ISection) => {
                //     navigate({ pathname: `/section/edit/${original.id}` })
                // }}
            />

        </InfiniteScroll>

    </>)

}
