import {IRoute} from "./types/layout";
import DashBoardIndex from "./pages/dashboard";
import SectionIndex from "./pages/section";
import SectionAdd from "./pages/section/add";
import SectionEdit from "./pages/section/edit";
import SectionDetail from "./pages/section/detail";
import UserIndex from "./pages/usermanagement";
import UserAdd from "./pages/usermanagement/add";
import UserEdit from "./pages/usermanagement/edit";

const AppRoutes: IRoute[] = [
    { path: '/', name: 'Dashboard ', element: <DashBoardIndex /> },

    { path: '/section', name: 'Section', element: <SectionIndex /> },
    { path: '/section/add', name: 'Add', element: <SectionAdd /> },
    { path: '/section/edit/:id', name: 'Edit', element: <SectionEdit /> },
    { path: '/section/detail/:id', name: 'Detail', element: <SectionDetail /> },

    { path: '/user', name: 'User', element: <UserIndex /> },
    { path: '/user/add', name: 'Add', element: <UserAdd /> },
    { path: '/user/edit/:id', name: 'Edit', element: <UserEdit /> },

]

export default AppRoutes