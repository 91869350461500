import React, {Component, useContext, useEffect} from 'react';
import {
    CAlert,
    CButton,
    CCard,
    CCardBody,
    CCol, CForm, CFormInput,
    CImage, CLoadingButton,
    CModal,
    CModalBody,
    CModalFooter,
    CRow
} from "@coreui/react-pro";
import {useNavigate, useSearchParams} from "react-router-dom";
import {z} from "zod";
import {SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import demo from "../assets/images/layout/demo.svg";
import logoNegative from "../assets/images/layout/logo-negative.png";

import api from "../api";
import {Context} from "../context";
import {GenericErrorHandler} from "../helpers";
import useToast from "../hooks/toast";


export function ModalLogin(){

    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const FormSchema = z.object({
        email: z.string().nullable().optional(),
        password: z.string().nullable().optional(),
    });

    type FormSchemaType = z.infer<typeof FormSchema>;

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
    } = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
    })
    const { visibleModalLogin, setVisibleModalLogin  } = useContext(Context)
    const [addToast] = useToast()
    const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {
        await api.post(`/auth/token`, data).then(response => {
            localStorage.setItem('token', JSON.stringify(response.data))
            navigate(0)

        }).catch(reason => {
            GenericErrorHandler({reason: reason, addToast: addToast, additionalErrorHandler: (reason) => {
                    for (let item  in reason.response.data.errors) {
                        setError(item as ("email" | "password"), { type: 'custom', message: reason.response.data.errors[item][0] })
                    }
                }})
        });
    }



    return(<CModal className={'bg-light'} portal backdrop={'static'} size='sm' alignment='center' visible={visibleModalLogin}>
        <CModalBody>
            <CForm
                id='loginform'
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className='mb-3'>
                    <CFormInput
                        type='text'
                        floatingLabel="Email"
                        placeholder="Email"
                        invalid={errors.email != null}
                        feedbackInvalid={errors.email?.message}
                        autoComplete={'username'}
                        {...register("email")}
                    />
                </div>
                <div className='mb-3'>
                    <CFormInput
                        type='password'
                        floatingLabel="Password"
                        placeholder="Password"
                        invalid={errors.password != null}
                        feedbackInvalid={errors.password?.message}
                        autoComplete={'current-password'}
                        {...register("password")}
                    />
                </div>
                <CRow>
                    <CCol md={12} className='d-grid'>
                        <CLoadingButton
                            size='lg'
                            type='submit'
                            form='loginform'
                            color='info'
                            loading={isSubmitting}
                        >
                            <span className='ms-2'>Masuk</span>
                        </CLoadingButton>
                    </CCol>
                </CRow>
            </CForm>
        </CModalBody>
    </CModal>)
}