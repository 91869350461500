import {useContext} from "react";
import {Context} from "../../context";
import {CAlert, CRow} from "@coreui/react-pro";

export default function Index(){
    const { user } = useContext(Context)

    return (<>
        <CRow>
            <CAlert color={"info"} dismissible>Welcome <strong>{user?.name}</strong></CAlert>
        </CRow>
    </>)
}