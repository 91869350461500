import React, {ReactElement, useEffect, useState} from 'react';
import {IUser} from "./types/entities/user";
import {IMetaData} from "./types/layout";
import {Context} from "./context";
import {CContainer, CToaster} from "@coreui/react-pro";
import {BrowserRouter, Outlet, Route, Routes} from "react-router-dom";
import useReloader from "./hooks/reloader";
import AppRoutes from "./AppRoutes";
import Error404 from "./Error404";
import Header from "./layout/header";
import {ModalLogin} from "./layout/modal.login";
import {ModelRefresh} from "./layout/modal.refresh";
import {ModalLogout} from "./layout/modal.logout";
import api from "./api";
import {GenericErrorHandler} from "./helpers";
import {IToken} from "./types/token";
import useToast from "./hooks/toast";

function App() {
  const [contextToast, setcontextToast] = useState<ReactElement | undefined>()
  const [contextUser, setContextUser] = useState<IUser>()

  const [contextVisibleModalLogin, setContextVisibleModalLogin] = useState(localStorage.getItem('token') == null || localStorage.getItem('token') === undefined)
  const [contextVisibleModalRefresh, setContextVisibleModalRefresh] = useState(false)
  const [contextVisibleModalLogout, setContextVisibleModalLogout] = useState(false)

  const [contextMetaData, setContextMetaData] = useState<IMetaData>({})
  const {notification, reload} = useReloader()
  const [addToast] = useToast()

  useEffect(() => {
    document.title = `${contextMetaData.title ? `${contextMetaData.title} | ` : ''}${process.env.REACT_APP_PUBLIC_SITE_NAME}`
  }, [contextMetaData.title])

  useEffect(() => {
    api.get('/auth/me').then(onfulfilled => {
      setContextUser(onfulfilled.data)
    }).catch(reason => {
      GenericErrorHandler({reason, addToast})
    })
  }, [setcontextToast, setContextUser])

  useEffect(() => {
    const interval = setInterval(async () => {
      const token : IToken = JSON.parse(localStorage.getItem('token') || JSON.stringify("{}"));

      if(!token.token){
        setContextVisibleModalLogin(true)
      }else{
        if(token.expires_at != null){
          const diff = new Date(token?.expires_at || new Date().toString()).getTime() - new Date().getTime();

          if (diff <= (1000*60*10)){//10 minutes
            setContextVisibleModalLogin(true)
          }else if(diff > 0 && diff < (1000*60*30)){ // 30 minutes
            setContextVisibleModalRefresh( true)
            await api.get(`/auth/refresh`).then(async onfulfilled =>{
              localStorage.setItem('token', JSON.stringify(onfulfilled.data))
              addToast({ message: "Token Refreshed", color: "success" })

            }).catch(reason => {
              GenericErrorHandler({reason, addToast})
              localStorage.clear()
            }).finally(() => {
              setContextVisibleModalRefresh(false)
            })
          }else{
            setContextVisibleModalLogin(false)
            setContextVisibleModalRefresh(false)
          }
        }else{
          setContextVisibleModalLogin(false)
          setContextVisibleModalRefresh(false)
        }
      }

    }, 1000);

    return () => clearInterval(interval);
  }, [addToast, setContextVisibleModalLogin, setContextVisibleModalRefresh]);

  return (<>
    <Context.Provider value={{
      user: contextUser,
      setUser: setContextUser,
      setToast: setcontextToast,
      setMetaData: setContextMetaData,
      visibleModalLogin: contextVisibleModalLogin,
      setVisibleModalLogin: setContextVisibleModalLogin,
      visibleModalRefresh: contextVisibleModalRefresh,
      setVisibleModalRefresh: setContextVisibleModalRefresh,
      visibleModalLogout: contextVisibleModalLogout,
      setVisibleModalLogout: setContextVisibleModalLogout,
      reloadQuestionnaire :reload,
      reloadQuestionnaireNotification: notification
    }}>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={
              <React.StrictMode>
                <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                  <Header/>
                  <div className="body flex-grow-1 px-3">
                    <CContainer xl className='pb-4'>
                      <Outlet/>
                    </CContainer>
                  </div>
                  <ModalLogin/>
                  <ModelRefresh/>
                  <ModalLogout/>
                </div>
              </React.StrictMode>}>
              {AppRoutes.map((route, key) => {
                return (route.element && <Route
                    key={key}
                    path={route.path}
                    element={route.element}
                />)
              })}
              <Route path="*" element={<Error404/>}/>
            </Route>
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </Context.Provider>
    <CToaster push={contextToast} placement="top-end" />
  </>)
}

export default App;
