import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react-pro'
import { FormAddEdit } from './componenet.form.add.edit'
import { useParams } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import {Context} from "../../context";

const Edit = () => {
    const { setMetaData } = useContext(Context)
    const params = useParams();
    const title = `Edit Section #${params.id}`

    useEffect(() => {
        setMetaData({ title })
    }, [setMetaData, title])

    return (<>
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader >
                        <strong>{title}</strong>
                    </CCardHeader>
                    <CCardBody>
                        <FormAddEdit edit={params.id} title={title} />
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </>)
}

export default Edit