
import { useContext, useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCol, CCollapse, CRow } from '@coreui/react-pro';
import ScrollToTop from 'react-scroll-to-top';
import { useNavigate, useSearchParams } from 'react-router-dom';

import CIcon from '@coreui/icons-react';
import { cilArrowTop, cilPlus, cisChevronBottom, cisChevronTop } from '@coreui/icons-pro';
import {Context} from "../../context";
import Table from "./componenet.table";
import FilterSorting from "./component.filter.sorting";

export default function Index() {
    const { setMetaData } = useContext(Context)
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const [collapseFilterSorting, setCollapseFilterSorting] = useState(searchParams.has('name') ||
        searchParams.has('name') ||
        searchParams.has('email') ||
        searchParams.has('role') ||
        searchParams.has('ordering')
    )

    useEffect(() => {
        setMetaData({
            title: "Section",
        })
    }, [setMetaData])

    return (<>
        <CRow>
            <CCol md={12}>
                <CButton color={'primary'} size='sm' className='me-2 mb-2' onClick={() => {
                    navigate({ pathname: "add" })
                }}><CIcon icon={cilPlus} className='me-1' />Add</CButton>
                <CButton active={collapseFilterSorting} color={collapseFilterSorting ? 'dark' : 'primary'} size='sm' className='me-2 mb-2' onClick={() => {
                    setCollapseFilterSorting(x => !x)
                }}> Filter & Sorting <CIcon className='ms-1' icon={collapseFilterSorting ? cisChevronBottom : cisChevronTop} /></CButton>
            </CCol>
        </CRow>

        <CCollapse visible={collapseFilterSorting}>
            <CRow className='mb-3'>
                <CCol>
                    <CCard>
                        <CCardBody>
                            <FilterSorting />
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CCollapse>
        <CRow>
            <CCol>
                <CCard>
                    <CCardBody>
                        <Table />
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <ScrollToTop smooth component={<CIcon icon={cilArrowTop} />} className='rounded-circle' />
    </>)
}
