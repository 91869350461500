import React, { useContext, useEffect } from 'react'
import {
    CCol,
    CContainer,
    CImage,
    CRow,
} from '@coreui/react-pro'
import { Link } from 'react-router-dom'

export default function Error404() {

    return (
        <div className="bg-light min-vh-100 d-flex flex-row">
            <CContainer lg>
                <CRow className="">
                    <CCol md={6}>
                        <div className="clearfix">
                            <CRow>
                                <CCol xs={12}>
                                    <p className='display-6'>Wild <strong>#404</strong> appeared!</p>
                                    <p className="text-medium-emphasis ">
                                        The page you're looking for cannot be found. <br/><Link to={{pathname: '/'}}>Go back Home!</Link>
                                    </p>
                                </CCol>
                            </CRow>

                        </div>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}