import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react-pro'
import { useContext, useEffect } from 'react'
import {Context} from "../../context";
import {FormAddEdit} from "./componenet.form.add.edit";

const Add = () => {
    const { setMetaData } = useContext(Context)
    const title = `Add User`

    useEffect(() => {
        setMetaData({ title })
    }, [setMetaData, title])

    return (<>
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader >
                        <strong>{title}</strong>
                    </CCardHeader>
                    <CCardBody>
                        <FormAddEdit title={title}/>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </>)
}

export default Add