export function GenericErrorHandler({reason, addToast, additionalErrorHandler} : {
    reason: any,
    addToast : (value: React.SetStateAction<{message: string, color: "danger" | "success" | "info" | "warning" | "dark"}>) => void,
    additionalErrorHandler? : (reason?: any) => void}
){
    if (reason.code === "ERR_NETWORK") {
        addToast({ message: reason.message, color: "danger" })
    } else {
        try {
            if(additionalErrorHandler)
                additionalErrorHandler(reason);
            addToast({ message: reason.response.data.message, color: "danger" })
        } catch {
            addToast({ message: "Undefined Error", color: "danger" })
        }
    }
}

export const GetGroup = (group?: string): string => {
    switch (group){
        case 'satgas':
            return 'Penilaian Satgas'
        case 'unsur':
            return 'Penilaian Unsur'
        default:
            return '';
    }
}