import { createContext } from "react";
import {IContext} from "./types/context";

const Context = createContext<IContext>({
    user : { },
    setUser : (x) => {},
    setToast : () => {},
    setMetaData : (x) => {},
    visibleModalLogin : false,
    setVisibleModalLogin : (x) => {},
    visibleModalRefresh: false,
    setVisibleModalRefresh:  (x) => {},
    visibleModalLogout: false,
    setVisibleModalLogout:  (x) => {},
    reloadQuestionnaireNotification: 0,
    reloadQuestionnaire: () => {}
});

export { Context };
